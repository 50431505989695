<template>
  <div class="case-item">
     <!-- @click="toDetail" -->
    <img :src="url" alt="" />
    <div class="info-box">
      <p class="name">{{title}}</p>
      <p class="info">
        {{brief}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url:{
        type: String,
        default:''
    },
    title:{
      type:String,
      default:'案例标题'
    },
    brief:{
      type:String,
      default:"简介"
    },
    id:{
      type:Number,
      default:0
    }
  },
  methods:{
    toDetail: function () {
      this.$router.push({ path: "/server-detail", query: { id: this.id } });
    },
  }
};
</script>
<style scoped>
.case-item {
  width: 285px;
  height: 300px;
  background: #fff;
  cursor: pointer;
}
.case-item img {
  width: 100%;
  height: 190px;
}
.case-item .info-box{
    padding: 20px 10px;
    overflow: hidden;
}
.case-item .name {
    color: #333;
    font-size: 15px;
    font-weight: 700;
}
.case-item .info{
    height: 40px;
    overflow: hidden;
    margin-top: 10px;
}
</style>