export default {
    data: function () {
        return {
            caseList: [],
            business_user:'',
            partner_user:"",
            standard_nums:'',
            case_description:''
        }
    },
    created(){
        this.getCaseList();
        this.getCaseStatistics();
    },
    methods: {
        getCaseList: function () {
            this.$post('/api/index/caseList.html', {}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.caseList  = res.data || []      
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        getCaseStatistics:function(){
            this.$post('/api/index/caseStatistics.html', {}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                 this.business_user = res.data.business_user || '';
                 this.case_description = res.data.case_description || ''
                 this.partner_user = res.data.partner_user || ''
                 this.standard_nums = res.data.standard_nums || ''
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    },
}