<template>
  <div class="case">
    <div class="banner">
      <div class="c-width">
        <h6>{{case_description}}</h6>
        <ul class="cf">
          <li class="fl">
            <p class="num">{{partner_user}} <span>+</span></p>
            <p class="hint">来自各领域的合作用户</p>
          </li>
          <li class="fl">
            <p class="num">{{standard_nums}} <span>+</span></p>
            <p class="hint">业务解决方案</p>
          </li>
          <li class="fl">
          <p class="num">{{business_user}} <span>+</span></p>
            <p class="hint">标测标注</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-width">
      <div class="list cf">
        <div class="fl item-box" v-for="item in caseList" :key="item.id">
          <caseItem  :brief='item.brief' :id="item.id" :url='item.url' :title='item.title'/>
        </div>
      </div>
      <!-- <el-pagination background layout="prev, pager, next" :total="1000" prev-text="上一页" next-text="下一页">
      </el-pagination> -->
    </div>
  </div>
</template>
<script>
import caseItem from "@/views/case/compoent/case-item";
import mixins from "../mixins";
export default {
  mixins,
  components: {
    caseItem,
  },
};
</script>
<style scoped>
@import "../case.css";
</style>